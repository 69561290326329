import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheqUI from '@combotag/cheq-ui';
import Chip from '../../../../../components/widgets/chip/index';
import DatePicker from '../../../../../widgets/date-picker';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { clearSummaryReportSettings, updateSummeryReportSettings } from '../../../../../redux/actions/analytics';
import moment from 'moment';
import selectOptions from './form-data/selectOptions';
import styles from './form.module.scss';
import useStyles from './form-data/styles';
import widgets from '../../../../../components/widgets';
import { useContentManager, useGetFilters } from '@hooks';
import { useSplit } from '@hooks';
import { constants } from '@utils/split.io';
import { NETWORK_SETTINGS } from '../../../../../utils/settings/constants';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { ToolTip, Toggler } from '@widgets';
import config from '../../../../../config';
import { SLP_REPORT_TYPE, SLP_LICENSE_ID } from '../../../../../utils/analytics/helpers';
import contentManager from '@services/content-manager';
import { useQuery } from 'react-query';
import api from '@services/api';
import { Products } from '../../../../../components/containers/management/constants';

const { useActions } = CheqUI.React.Meiosis;
const { MultiSelectCheckbox, Button } = widgets;
let {
    deviceOptions,
    breakdownOptions,
    getGroupByOptions,
    browserOptions,
    dateRangeOptions: dateRangeOptionsConstants,
    countriesOptions,
    reportTypeOptions,
    groupByOptionsAffiliate,
    groupByOptionsRTI,
    groupByOptionsSLP,
} = selectOptions;

const capitalize = (str = '') => {
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.substr(1))
        .join(' ');
};

function setDefaults(state, setState, cm, selectedLicense, showPolicyToggle, policyManagementValue) {
    let reportType;
    if (selectedLicense === 1 || selectedLicense === 3) {
        reportType = {
            label: 'Summary',
            value: 'summary',
        };
    } else if (selectedLicense === SLP_LICENSE_ID) {
        reportType = {
            label: contentManager.get('UserValidation'),
            value: SLP_REPORT_TYPE,
        };
    } else {
        reportType = {
            label: 'Real-time Interception API',
            value: 'rti',
        };
    }
    const { label: reportTypeLabel, value: reportTypeValue } = reportType;
    const { label: dateRangeLabel, value: dateRangeValue } = dateRangeOptionsConstants[0];

    const defaultReportType = { label: reportTypeLabel, value: reportTypeValue };
    const defaultDateRange = { label: cm.get(dateRangeLabel), value: dateRangeValue };
    const policyToggleA = state.policyToggleA;
    const includePolicy = showPolicyToggle;
    const policyManagement = policyManagementValue;

    if (!state.reportType && !state.dateRange) {
        setState(state => ({
            ...(state || {}),
            reportType: defaultReportType,
            dateRange: defaultDateRange,
            policyToggleA,
            includePolicy,
            policyManagement,
        }));
    } else if (state.reportType && !state.dateRange) {
        setState(state => ({ ...(state || {}), dateRange: defaultDateRange }));
    } else if (!state.reportType && state.dateRange) {
        setState(state => ({ ...(state || {}), reportType: defaultReportType }));
    }
}

export default function ({
    state,
    setState,
    onSubmit,
    affiliates,
    eventType,
    eventName,
    urls,
    domains,
    contents,
    mediums,
    sources,
    campaigns,
    channels,
    isLoading,
    slpEmailDomains,
    threatTypes,
    slpDomains,
}) {
    const dispatch = useDispatch();
    const summaryReportSettings = useSelector(state => state.analytics.summaryReportSettings);
    const { tz } = useSelector(state => state.profile.meta.settings);
    const { isOn: showPolicyToggle } = useSplit(constants.SPLIT_POLICY_MANAGEMENT_TOGGLE);
    const { isOn: policyManagement } = useSplit(constants.SPLIT_POLICY_MANAGEMENT);
    const { isOn: domainFilterOn } = useSplit(constants.PARADOME_DOMAIN_FILTER);
    const { isOn: affiliateFilterOn } = useSplit(constants.PARADOME_AFFILIATE_FILTER);
    const { data: filtersData } = useGetFilters(true);
    const cm = useContentManager();
    const { selectedLicense } = useSelector(state => state.settings);
    const {
        settings: { language },
        networkId,
    } = useSelector(state => state.profile.meta);
    const { data: rtiSettings } = useQuery(['rti_settings'], api.settings.getRtiSettings);

    const shouldHideRunReportButton = selectedLicense === Products.OSS && rtiSettings?.isClientSideIntegration && !rtiSettings?.isApiIntegration;

    const [reportTypeSelectOptions, setReportTypeSelectOptions] = useState([]);
    const [dateRangeOptions, setDateRangeOptions] = useState(dateRangeOptionsConstants);

    const isAffiliateFraudEnabled = useSelector(state => state.settings.isAffiliateFraudEnabled);
    const platformStatus = [
        { label: 'Protected', value: 'protected' },
        { label: 'Monitored', value: 'monitored' },
    ];

    const platformOrigin = filtersData?.data?.platforms || [];

    useEffect(() => {
        if (selectedLicense === 1) {
            if (networkId === parseInt(config.dellNetworkId)) {
                if (!dateRangeOptions.some(oldDateRange => oldDateRange.value === 'last12Months')) {
                    setDateRangeOptions(oldDateRanges => {
                        oldDateRanges.splice(9, 0, { label: 'Last12Months', value: 'last12Months' });
                        return oldDateRanges;
                    });
                }
            }
            setReportTypeSelectOptions(reportTypeOptions);
            if (isAffiliateFraudEnabled) {
                setReportTypeSelectOptions([
                    { label: 'Summary', value: 'summary' },
                    {
                        label: 'Affiliation report',
                        value: 'affiliate_fraud',
                    },
                    { label: 'Invalid Affiliate Visits', value: 'affiliate_fraud_invalid' },
                ]);
            }
        }
        if (selectedLicense === 2) {
            setReportTypeSelectOptions([{ label: 'Real-time Interception API', value: 'rti' }]);
        }
        if (selectedLicense === 3) {
            setReportTypeSelectOptions([{ label: 'Summary', value: 'summary' }]);
        }
        if (selectedLicense === SLP_LICENSE_ID) {
            setReportTypeSelectOptions([{ label: contentManager.get('UserValidation'), value: SLP_REPORT_TYPE }]);
        }
    }, [selectedLicense, networkId]);

    const storeSettingsApplied = useRef(false);
    const classes = useStyles();
    const actions = useActions();
    const [isAFExpended, setIsAFExpended] = useState(false);

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [datePickerInput, setDatePickerInput] = useState(false);
    const [datePickerValue, setDatePickerValue] = useState([]);
    const initialgroupByOptions = useMemo(() => getGroupByOptions(domainFilterOn, affiliateFilterOn), [domainFilterOn, affiliateFilterOn]);
    const [groupBySelectOptions, setGroupBySelectOptions] = useState(initialgroupByOptions);
    const [reportSettings, setSummaryReportSettings] = useState(summaryReportSettings);
    const { isOn: isNewDashboard } = useSplit(constants.SPLIT_PARADOME_NEW_PD_DASHBOARD);
    useEffect(() => {
        setState(state => ({
            ...state,
            includePolicy: showPolicyToggle,
            policyManagement,
        }));
        setSummaryReportSettings(state => ({
            ...state,
            includePolicy: showPolicyToggle,
            policyManagement,
        }));
        dispatch(
            updateSummeryReportSettings({
                includePolicy: showPolicyToggle,
                policyManagement,
            })
        );
    }, [showPolicyToggle, policyManagement]);

    useEffect(() => {
        setState(state => ({
            ...state,
            includePolicy: showPolicyToggle,
            policyManagement,
        }));
    }, [reportSettings]);

    const onFormChange = formValue => {
        if (formValue.dateRange && formValue.dateRange.value === 'Custom') {
            setDatePickerOpen(true);
            setDatePickerInput(true);
            setState(s => ({
                ...(s || {}),
                dateRange: { label: formValue.dateRange.value },
                ...formValue,
                includePolicy: showPolicyToggle,
                policyManagement,
                policyToggleA: formValue.policyToggleA,
            }));
            if (formValue.policyToggleA) {
                setSummaryReportSettings(state => ({ ...state, policyToggleA: formValue.policyToggleA }));
                dispatch(updateSummeryReportSettings({ policyToggleA: formValue.policyToggleA }));
            }
        } else {
            setState(state => ({ ...(state || {}), ...formValue, includePolicy: showPolicyToggle, policyManagement, policyToggleA: formValue.policyToggleA }));
            if (formValue.policyToggleA) {
                setSummaryReportSettings(state => ({ ...state, policyToggleA: formValue.policyToggleA }));
                dispatch(updateSummeryReportSettings({ policyToggleA: formValue.policyToggleA }));
            }
            if (formValue.reportType?.value === 'summary') {
                // ugly fix to reset summary settings when entering the tab... until you'll refactor this, sorry :)
                setState(s => ({
                    ...(s || {}),
                    groupBy: [{ label: capitalize('platformOrigin'), value: 'platformOrigin' }].map(parseLabel(cm)),
                }));
            }
            if (formValue.dateRange && formValue.dateRange.value !== 'Custom') {
                datePickerValue.length && setDatePickerValue([]);
                setDatePickerInput(false);
            }
        }
    };

    const handleOnDatePickerChange = date => {
        if (date.length === 2) {
            const { dateRange } = state;

            const startDate = moment(date[0]).startOf('day').toDate();
            const endDate = moment(date[1]).endOf('day').toDate();

            const newDateRange = { ...dateRange, value: { startDate, endDate } };
            setState(s => ({ ...(s || {}), dateRange: newDateRange }));
            setDatePickerValue(date);
        }
    };

    const handleOnDatePickerClose = date => {
        date.length <= 1 && clearAll();
        setDatePickerOpen(false);
    };

    const removeGroupByFilters = remove => {
        const groupBy = (state.groupBy || []).filter(x => x.label !== remove);
        onFormChange({ groupBy });
    };

    const clearAll = () => {
        datePickerInput && setDatePickerInput(false);
        let defaultType;
        switch (selectedLicense) {
            case 1:
            case 3:
                defaultType = {
                    label: 'Summary',
                    value: 'summary',
                };
                break;
            case 2:
                {
                    // on-site
                    defaultType = {
                        label: 'Real-time Interception API',
                        value: 'rti',
                    };
                }
                break;
            case SLP_LICENSE_ID: {
                defaultType = {
                    label: cm.get('UserValidation'),
                    value: SLP_REPORT_TYPE,
                };
            }
        }
        setState({ reportType: defaultType });
    };

    const AffiliateAdvancedFiltering = () => (
        <div style={{ width: '275px' }}>
            <MultiSelectCheckbox
                id={'source'}
                value={state.source}
                placeholder={cm.get('Sources')}
                onChange={source => onFormChange({ source })}
                options={sources}
                isLoading={actions.analytics.isLoading('get_source')}
                isMulti={true}
            />
            <MultiSelectCheckbox
                id={'content'}
                value={state.content}
                placeholder={cm.get('Content')}
                onChange={content => onFormChange({ content })}
                options={contents}
                isLoading={actions.analytics.isLoading('get_contents')}
                isMulti={true}
            />
            <MultiSelectCheckbox
                id={'medium'}
                value={state.medium}
                placeholder={cm.get('Medium')}
                onChange={medium => onFormChange({ medium })}
                options={mediums}
                isLoading={actions.analytics.isLoading('get_medium')}
                isMulti={true}
            />
            <MultiSelectCheckbox
                id={'campaigns'}
                placeholder={cm.get('Campaigns')}
                value={state.campaign}
                onChange={campaign => onFormChange({ campaign })}
                options={campaigns}
                isLoading={actions.analytics.isLoading('get_campaigns')}
                isMulti={true}
            />
            <MultiSelectCheckbox
                id={'channels'}
                placeholder={cm.get('Channels')}
                value={state.channel}
                onChange={channel => onFormChange({ channel })}
                options={channels}
                isMulti={true}
            />
            <MultiSelectCheckbox
                id={'affiliates'}
                placeholder={cm.get('AffiliateID')}
                value={state.affiliates}
                onChange={affiliates => onFormChange({ affiliates })}
                options={affiliates}
                isLoading={actions.analytics.isLoading('get_affiliates')}
                isMulti={true}
            />
        </div>
    );

    const AdvancedFiltering = ({ reportType }) => {
        return (
            <div style={{ width: '275px' }}>
                {reportType === 'rti' ? (
                    <>
                        <MultiSelectCheckbox
                            id={'eventTypes'}
                            placeholder={cm.get('EventType')}
                            value={state.eventType}
                            onChange={eventType => onFormChange({ eventType })}
                            options={eventType}
                            isLoading={actions.analytics.isLoading('get_rti_event_types')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'eventName'}
                            placeholder={cm.get('EventName')}
                            value={state.eventName}
                            onChange={eventType => onFormChange({ eventType })}
                            options={eventName}
                            isLoading={actions.analytics.isLoading('get_rti_event_names')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'platformOrigin'}
                            placeholder={cm.get('PlatformOrigin')}
                            value={state.platformOrigin}
                            onChange={platformOrigin => onFormChange({ platformOrigin })}
                            options={platformOrigin}
                            isLoading={actions.realtime.isLoading('platformOrigin')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'url'}
                            placeholder={cm.get('Analytics.Form.DropDown.GroupBy.URL')}
                            value={state.url}
                            onChange={url => onFormChange({ url })}
                            options={urls}
                            isLoading={actions.realtime.isLoading('get_rti_urls')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'countries'}
                            value={state.country}
                            onChange={country => onFormChange({ country })}
                            placeholder={cm.get('Countries')}
                            options={countriesOptions}
                            isMulti={true}
                        />
                    </>
                ) : reportType === SLP_REPORT_TYPE ? (
                    <>
                        <MultiSelectCheckbox
                            id={'countries'}
                            value={state.country}
                            onChange={country => onFormChange({ country })}
                            placeholder={cm.get('Countries')}
                            options={countriesOptions}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'domain'}
                            placeholder={cm.get('Domain')}
                            value={state.domain}
                            onChange={domain => onFormChange({ domain })}
                            options={slpDomains}
                            isLoading={actions.realtime.isLoading('getCasDomains')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'emailDomain'}
                            placeholder={cm.get('EmailDomain')}
                            value={state.emailDomains}
                            onChange={emailDomains => onFormChange({ emailDomains })}
                            options={slpEmailDomains}
                            isLoading={actions.realtime.isLoading('getSlpEmailDomains')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'threatType'}
                            placeholder={cm.get('ThreatType')}
                            value={state.threatTypes}
                            onChange={threatTypes => onFormChange({ threatTypes })}
                            options={threatTypes}
                            isLoading={actions.analytics.isLoading('threatTypes')}
                            isMulti={true}
                        />
                    </>
                ) : (
                    <>
                        <MultiSelectCheckbox
                            id={'countries'}
                            value={state.country}
                            onChange={country => onFormChange({ country })}
                            placeholder={cm.get('Countries')}
                            options={countriesOptions}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'device'}
                            value={state.device}
                            placeholder={cm.get('Devices')}
                            options={deviceOptions}
                            onChange={device => onFormChange({ device })}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'browser'}
                            value={state.browser}
                            placeholder={cm.get('Browsers')}
                            onChange={browser => onFormChange({ browser })}
                            options={browserOptions}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'source'}
                            value={state.source}
                            placeholder={cm.get('Sources')}
                            onChange={source => onFormChange({ source })}
                            options={sources}
                            isLoading={actions.analytics.isLoading('get_source')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'campaigns'}
                            placeholder={cm.get('Campaigns')}
                            value={state.campaign}
                            onChange={campaign => onFormChange({ campaign })}
                            options={campaigns}
                            isLoading={actions.analytics.isLoading('get_campaigns')}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'channels'}
                            placeholder={cm.get('Channels')}
                            value={state.channel}
                            onChange={channel => onFormChange({ channel })}
                            options={channels}
                            isMulti={true}
                        />
                        <MultiSelectCheckbox
                            id={'platformOrigin'}
                            placeholder={cm.get('PlatformOrigin')}
                            value={state.platformOrigin}
                            onChange={platformOrigin => onFormChange({ platformOrigin })}
                            options={platformOrigin}
                            isLoading={actions.realtime.isLoading('platformOrigin')}
                            isMulti={true}
                        />
                        {domainFilterOn && (
                            <MultiSelectCheckbox
                                id={'domain'}
                                placeholder={cm.get('Domain')}
                                value={state.domain}
                                onChange={domain => onFormChange({ domain })}
                                options={domains}
                                isLoading={actions.realtime.isLoading('getCasDomains')}
                                isMulti={true}
                            />
                        )}
                        {affiliateFilterOn && JSON.parse(window.localStorage.getItem(NETWORK_SETTINGS)).isAffiliateFraudEnabled !== 0 && (
                            <MultiSelectCheckbox
                                id={'affiliate'}
                                placeholder={cm.get('Affiliate')}
                                value={state.affiliates}
                                onChange={affiliates => onFormChange({ affiliates })}
                                options={affiliates}
                                isLoading={actions.realtime.isLoading('getCasAffiliates')}
                                isMulti={true}
                            />
                        )}
                        {isNewDashboard ?? (
                            <MultiSelectCheckbox
                                id={'platformStatus'}
                                placeholder={'Platform Status'}
                                value={state.platformStatus}
                                onChange={platformStatus => onFormChange({ platformStatus })}
                                options={platformStatus}
                                isMulti={true}
                            />
                        )}
                    </>
                )}
            </div>
        );
    };

    const getCustomDateRange = ({ startDate: startDateString, endDate: endDateString }) => {
        const startDate = moment(startDateString).startOf('day').toDate();
        const endDate = moment(endDateString).endOf('day').toDate();

        return { label: 'Custom', value: { startDate: startDate, endDate: endDate } };
    };

    useEffect(() => {
        if (!storeSettingsApplied.current && summaryReportSettings && summaryReportSettings.isDirty) {
            const dateRange = getCustomDateRange(summaryReportSettings.dateRange);
            const newSummaryReportSettingss =
                'value' in summaryReportSettings.dateRange
                    ? summaryReportSettings
                    : {
                          ...summaryReportSettings,
                          dateRange,
                      };
            newSummaryReportSettingss.policyToggleA = newSummaryReportSettingss.policyToggleA ?? true;
            newSummaryReportSettingss.includePolicy = newSummaryReportSettingss.includePolicy ?? showPolicyToggle;
            newSummaryReportSettingss.policyManagement = newSummaryReportSettingss.policyManagement ?? policyManagement;
            const newState = { ...(state || {}), ...newSummaryReportSettingss };

            setState(() => newState);

            storeSettingsApplied.current = true;
            dispatch(clearSummaryReportSettings());

            return onSubmit(newState);
        }
        setDefaults(state, setState, cm, selectedLicense, showPolicyToggle, policyManagement);
    }, [dispatch, onSubmit, setState, state, summaryReportSettings, cm]);

    useEffect(() => {
        if (state.country || state.device || state.browser || state.source || state.campaign || state.channel || state.platformsOrigin || state.affiliates) {
            setIsAFExpended(true);
        }
    }, [state]);

    useEffect(() => {
        if (summaryReportSettings.isDirty && 'startDate' in summaryReportSettings.dateRange) {
            const { startDate, endDate } = summaryReportSettings.dateRange;

            const startDateOffsetInHours = Math.abs(moment.duration(moment(startDate).tz(tz).format('Z')).asHours());
            const endDateOffsetInHours = Math.abs(moment.duration(moment(endDate).tz(tz).format('Z')).asHours());

            const defaultDate =
                moment.duration(moment(startDate).tz(tz).format('Z')).asHours() > 0
                    ? [moment(startDate.slice(0, -1)).add(startDateOffsetInHours, 'hour').toDate(), moment(endDate.slice(0, -1)).add(endDateOffsetInHours, 'hour').toDate()]
                    : [
                          moment(startDate.slice(0, -1)).subtract(startDateOffsetInHours, 'hour').toDate(),
                          moment(endDate.slice(0, -1)).subtract(endDateOffsetInHours, 'hour').toDate(),
                      ];

            setDatePickerValue(defaultDate);
            setDatePickerInput(true);
        }
    }, [summaryReportSettings]);

    useEffect(() => {
        if (state.reportType?.value === 'affiliate_fraud' || state.reportType?.value === 'affiliate_fraud_invalid') {
            setGroupBySelectOptions(groupByOptionsAffiliate);
            breakdownOptions = breakdownOptions.filter(option => option.value !== 'year');
            setState(s => ({
                ...(s || {}),
                groupBy: [{ label: capitalize('ThreatType'), value: 'threatType' }].map(parseLabel(cm)),
            }));
        } else if (state.reportType?.value === 'rti') {
            setGroupBySelectOptions(groupByOptionsRTI);
            breakdownOptions = breakdownOptions.filter(option => option.value !== 'year');
            setState(s => ({
                ...(s || {}),
                groupBy: [{ label: capitalize('EventType'), value: 'eventType' }].map(parseLabel(cm)),
            }));
        } else if (state.reportType?.value === SLP_REPORT_TYPE) {
            setGroupBySelectOptions(groupByOptionsSLP);
            breakdownOptions = breakdownOptions.filter(option => option.value !== 'year');
            // setState(s => ({
            //     ...(s || {}),
            //     groupBy: [{ label: capitalize('ThreatType'), value: 'threatType' }].map(parseLabel(cm)),
            // }));
        } else {
            setGroupBySelectOptions(initialgroupByOptions);
            setState(s => ({
                ...(s || {}),
                groupBy: [...(s.groupBy?.filter(item => item.value !== 'platformOrigin') || [])],
            }));
        }
    }, [state.reportType?.value]);

    const handleIncludeCrawler = (e, checked) => {
        setSummaryReportSettings(prevState => ({
            ...prevState,
            includePPCCrawlers: { value: checked },
        }));
        setState(state => ({
            ...state,
            includePPCCrawlers: { value: checked },
        }));
        dispatch(updateSummeryReportSettings({ includePPCCrawlers: checked }));
    };

    const handleIncludePolicy = (e, checked) => {
        setSummaryReportSettings(prevState => ({
            ...prevState,
            // policyTogglePM: checked,
            policyToggleA: checked,
        }));
        setState(state => ({
            ...state,
            policyToggleA: checked,
        }));
        dispatch(updateSummeryReportSettings({ policyToggleA: checked }));
        //dispatch(setPolicyToggleOptions({ policyTogglePM: checked }));
    };
    const policyToggleOffDatesInRange = dates => {
        if (networkId === parseInt(config.dellNetworkId)) {
            return '';
        }
        let start;
        let end;
        const dr = state.dateRange ? state.dateRange : summaryReportSettings.dateRange;
        const date = dates.length !== 0 ? dates : dateRangeToDate(dr, 'UTC');
        if (moment.unix(date[0]) < moment.unix(date[1])) {
            start = moment(date[0], 'YYYY-MM-DD HH:mm');
            end = moment(date[1], 'YYYY-MM-DD HH:mm');
        } else {
            start = moment(date[1], 'YYYY-MM-DD HH:mm');
            end = moment(date[0], 'YYYY-MM-DD HH:mm');
        }
        if (!Array.isArray(date) && (dr.value === 'today' || dr.value === 'yesterday')) {
            start = moment(date, 'YYYY-MM-DD HH:mm');
            end = moment(date, 'YYYY-MM-DD HH:mm');
        }
        const pivot = moment(moment.unix(process.env.REACT_APP_MIN_POLICY_DATE), 'YYYY-MM-DD HH:mm');

        if (end.isBefore(pivot)) return `${cm.get('NoAvailableDataForTheRequestedDates')}`;
        else if (start.isAfter(pivot)) {
            return '';
        } else {
            return `${cm.get('AvailableDataFrom')} ${pivot.format('MMM D, YYYY')}`;
        }
    };

    const dateRangeToDate = (dateRange, timezone) => {
        const yesterday = moment.tz(timezone).subtract(1, 'days');
        if (dateRange.startDate && dateRange.endDate) {
            return [moment(dateRange.startDate, 'YYYY-MM-DD HH:mm').tz(timezone), moment(dateRange.endDate, 'YYYY-MM-DD HH:mm').tz(timezone)];
        }
        switch (dateRange.value) {
            case 'today':
                return moment.tz(timezone);
            case 'yesterday':
                return yesterday;
            case 'last7Days':
                return [yesterday, moment.tz(timezone).subtract(7, 'days')];
            case 'currentMonth':
                return [yesterday, moment.tz(timezone).subtract(moment.tz(timezone).date(), 'days')];
            case 'lastMonth':
                return [yesterday, moment.tz(timezone).subtract(30, 'days')];
            case 'last3Months':
                return [moment.tz(timezone).subtract(1, 'month').endOf('month').endOf('day'), moment.tz(timezone).subtract(3, 'months')];
            case 'last6Months':
                return [moment.tz(timezone).subtract(1, 'month').endOf('month').endOf('day'), moment.tz(timezone).subtract(6, 'months')];
            case 'last12Months':
                return [moment.tz(timezone).subtract(1, 'month').endOf('month').endOf('day'), moment.tz(timezone).subtract(12, 'months')];
            default:
                return moment.tz(timezone);
        }
    };

    return (
        <div className={classes.filtering}>
            <Header {...{ classes, clearAll, cm, state }} />
            <MultiSelectCheckbox
                id={'selectReport'}
                value={state.reportType}
                placeholder={'Select Report'}
                onChange={reportType => onFormChange({ reportType })}
                options={reportTypeSelectOptions}
                styles={{
                    container: {
                        width: '-webkit-fill-available',
                        marginLeft: '10px',
                        marginBottom: '5px',
                    },
                    placeholder: {
                        top: 8,
                    },
                }}
            />
            <MultiSelectCheckbox
                id={'dateRange'}
                value={state.dateRange || (state.reportType && dateRangeOptions[0])}
                placeholder={'Date Range'}
                onChange={dateRange => onFormChange({ dateRange })}
                options={dateRangeOptions.map(parseDateLabel(cm))}
                styles={{
                    container: {
                        width: '-webkit-fill-available',
                        marginLeft: '10px',
                    },
                    placeholder: {
                        top: 8,
                    },
                }}
            />

            {datePickerInput && (
                <div className={styles.datePickerWrapper}>
                    <DatePicker
                        includeLast12Months={networkId === parseInt(config.dellNetworkId)}
                        open={datePickerOpen}
                        value={datePickerValue || []}
                        locale={language}
                        options={{
                            onChange: handleOnDatePickerChange,
                            onClose: handleOnDatePickerClose,
                        }}
                        className={styles.datePickerInput}
                        fullWidth={true}
                    />
                </div>
            )}

            <MultiSelectCheckbox
                id={'breakdown'}
                value={state.breakdownBy}
                placeholder={cm.get('SelectBreakdown')}
                onChange={breakdownBy => {
                    onFormChange({ breakdownBy });
                }}
                options={breakdownOptions.map(parseLabel(cm))}
                styles={{
                    container: {
                        width: '-webkit-fill-available',
                        marginLeft: '10px',
                    },
                    placeholder: {
                        top: 8,
                    },
                }}
            />
            <MultiSelectCheckbox
                id={'groupBy'}
                value={[]}
                placeholder={cm.get('AddGroupBy')}
                onChange={groupBy => (!groupBy ? onFormChange({ groupBy: [] }) : onFormChange({ groupBy: [...(state.groupBy || []), groupBy] }))}
                options={filterSelectByState(groupBySelectOptions, state.groupBy).map(parseLabel(cm))}
                styles={{
                    container: {
                        width: '-webkit-fill-available',
                        marginLeft: '10px',
                        border: state.groupBy && state.groupBy.length ? 'solid 1px #fe0072' : 'solid 1px #14172c',
                        ':hover': {
                            border: state.groupBy && state.groupBy.length ? 'solid 1px #fe0072' : 'solid 1px #14172c',
                        },
                        ':focus': {
                            border: state.groupBy && state.groupBy.length ? 'solid 1px #fe0072' : 'solid 1px #14172c',
                        },
                    },
                    placeholder: {
                        top: 8,
                    },
                }}
            />
            <div className={classes.groupByList}>
                {(state.groupBy || []).map(({ label, value }) => (
                    <Chip label={label} key={value} onDelete={removeGroupByFilters} />
                ))}
            </div>
            {showPolicyToggle && selectedLicense !== SLP_LICENSE_ID ? (
                <div
                    style={{
                        display: 'flex',
                        marginLeft: '15px',
                        alignItems: 'center',
                        height: '50px',
                        paddingTop: 10,
                    }}
                >
                    <Toggler
                        data-test-selector="paidmarketing-known-bots-toggle"
                        onChange={handleIncludePolicy}
                        checked={state.policyToggleA ? state.policyToggleA : summaryReportSettings.policyToggleA || false}
                        label={
                            <>
                                <span>{cm.get('IncludePolicy')}</span>
                                <ToolTip title={cm.get('IncludePolicy.Title')} content={cm.get('IncludePolicy.Description')}>
                                    <InfoIcon cursor="pointer" />
                                </ToolTip>
                            </>
                        }
                    />
                </div>
            ) : (
                selectedLicense !== SLP_LICENSE_ID && (
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: '35px',
                            alignItems: 'center',
                            height: '50px',
                            paddingTop: 10,
                        }}
                    >
                        <Toggler
                            data-test-selector="paidmarketing-known-bots-toggle"
                            onChange={handleIncludeCrawler}
                            checked={summaryReportSettings.includePPCCrawlers || false}
                            label={
                                <>
                                    <span>{cm.get('IncludePPCCrawlers')}</span>
                                    <ToolTip title={cm.get('IncludePPCCrawlers')} content={cm.get('IncludePPCCrawlersToolTip')}>
                                        <InfoIcon cursor="pointer" />
                                    </ToolTip>
                                </>
                            }
                        />
                    </div>
                )
            )}
            {!state.policyToggleA && (
                <span
                    style={{
                        background: 'gray',
                        fontSize: '1.0rem',
                        width: 'max-content',
                        marginLeft: '15px',
                    }}
                >
                    {policyToggleOffDatesInRange(datePickerValue || state.dateRange)}
                </span>
            )}
            <Accordion
                expanded={isAFExpended}
                style={{
                    width: '-webkit-fill-available',
                    minHeight: '69px',
                    border: '2px solid rgba(0, 0, 0, .325)',
                    backgroundColor: '#282b3f',
                    marginLeft: '10px',
                    boxShadow: 'none',
                }}
                onChange={() => setIsAFExpended(!isAFExpended)}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary style={{ display: 'flex', justifyItems: 'center', height: '69px', width: '100%', padding: 0 }}>
                    <div
                        style={{
                            fontSize: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '300px',
                            height: '18px',
                            fontWeight: 'normal',
                            fontStyle: 'normal',
                            fontStretch: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: isAFStateChange(state) ? '#fff' : '#fe0072',
                        }}
                    >
                        {cm.get('AdvancedFiltering')}
                        {!isAFExpended ? (
                            <AddCircleOutlineIcon style={{ color: '#fe0072', marginLeft: '5px' }} />
                        ) : (
                            <HighlightOffIcon style={{ color: '#fe0072', marginLeft: '5px' }} />
                        )}
                    </div>
                </AccordionSummary>

                <AccordionDetails style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {isAFExpended &&
                        (state.reportType?.value === 'summary' || state.reportType?.value === 'rti' || state.reportType?.value === SLP_REPORT_TYPE ? (
                            <AdvancedFiltering reportType={state.reportType?.value} />
                        ) : (
                            <AffiliateAdvancedFiltering />
                        ))}
                </AccordionDetails>
            </Accordion>
            {/* ) : (
                <div style={{ width: '300px' }}></div>
            )} */}
            {!shouldHideRunReportButton && (
                <div className={styles.buttonWrapper}>
                    <Button fullWidth disabled={isLoading} onClick={() => onSubmit(state)}>
                        {cm.get('RunReport')}
                    </Button>
                </div>
            )}
        </div>
    );
}

const Header = ({ classes, state, cm, clearAll }) => (
    <div className={classes.header}>
        <input
            className={classes.clearAllButton}
            style={{
                backgroundColor: Object.keys(state).filter(
                    value =>
                        state[value] &&
                        ((Object.keys(state[value]).length && value !== 'reportType' && value !== 'dateRange') || (value === 'dateRange' && state[value].value !== 'today'))
                ).length
                    ? '#fe0072'
                    : '#383851',
            }}
            value={cm.get('ClearAll')}
            type="button"
            onClick={() => clearAll()}
        />
    </div>
);

function isAFStateChange(state) {
    return (
        (state.campaign && state.campaign.length) ||
        (state.source && state.source.length) ||
        (state.browser && state.browser.length) ||
        (state.device && state.device.length) ||
        (state.country && state.country.length) ||
        (state.channel && state.channel.length) ||
        (state.medium && state.medium.length) ||
        (state.content && state.content.length) ||
        (state.affiliates && state.affiliates.length) ||
        (state.domain && state.domain.length) ||
        state.emailDomains?.length
    );
}

function filterSelectByState(options = [], currentState = []) {
    return options
        .filter(option => !currentState.find(c => option.value === c.value))
        .map(option => ({
            value: option.value,
            label: capitalize(option.label),
        }));
}

const parseLabel =
    cm =>
    ({ label, ...rest }) => ({ ...rest, label: cm.get(label) });

const parseDateLabel =
    cm =>
    ({ label, ...rest }) => {
        return { ...rest, label: cm.get(`Analytics.Form.DropDown.Date.${label}`) };
    };
